// assets
import { IconUsers, IconShieldLock } from '@tabler/icons';

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const other = {
  id: 'sample-docs-roadmap',
  type: 'group',
  children: [
    // {
    //   id: 'stock-list',
    //   title: '库存记录',
    //   type: 'item',
    //   url: '/stock/list',
    //   icon: IconHistory,
    //   breadcrumbs: false
    // },
    {
      id: 'zhibao',
      title: '质保管理',
      type: 'item',
      url: '/customer/zhibao',
      icon: IconShieldLock,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: '用户管理',
      type: 'item',
      url: '/sys/users',
      icon: IconUsers,
      admin: true,
      breadcrumbs: false
    }
  ]
};

export default other;
