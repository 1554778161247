export default function getUser() {
  try {
    const userString = localStorage.getItem('user');
    if (userString) {
      return JSON.parse(userString);
    }
  } catch (error) {
    console.error(error);
  }
  return null;
}
