import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// merchant routing
const VerifiedShop = Loadable(lazy(() => import('views/merchant/VerifiedShop')));
const PendingShop = Loadable(lazy(() => import('views/merchant/PendingShop')));
const Charge = Loadable(lazy(() => import('views/merchant/Charge')));
const Claim = Loadable(lazy(() => import('views/merchant/Claim')));
const Withdraw = Loadable(lazy(() => import('views/merchant/Withdraw')));
const Balance = Loadable(lazy(() => import('views/merchant/Balance')));
const StockRecord = Loadable(lazy(() => import('views/merchant/StockRecord')));
const SysUsers = Loadable(lazy(() => import('views/SysUsers')));
const Zhibao = Loadable(lazy(() => import('views/merchant/Zhibao')));
const TradeList = Loadable(lazy(() => import('views/trade/List')));
const TradeArrange = Loadable(lazy(() => import('views/trade/Arrange')));
const Favorites = Loadable(lazy(() => import('views/trade/Favorites')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <VerifiedShop />
    },
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/merchant/verified',
      element: <VerifiedShop />
    },
    {
      path: '/merchant/pending',
      element: <PendingShop />
    },
    {
      path: '/merchant/charge',
      element: <Charge />
    },
    {
      path: '/merchant/balance',
      element: <Balance />
    },
    {
      path: '/merchant/claim',
      element: <Claim />
    },
    {
      path: '/merchant/withdraw',
      element: <Withdraw />
    },
    {
      path: '/trade/list',
      element: <TradeList />
    },
    {
      path: '/trade/arrange',
      element: <TradeArrange />
    },
    {
      path: '/trade/favorites',
      element: <Favorites />
    },
    {
      path: '/customer/zhibao',
      element: <Zhibao />
    },
    {
      path: '/stock/list',
      element: <StockRecord />
    },
    {
      path: '/sys/users',
      element: <SysUsers />
    }
  ]
};

export default MainRoutes;
