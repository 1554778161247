import trade from './trade';
import other from './other';
import merchant from './merchant';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
  items: [merchant, trade, other]
};

export default menuItems;
