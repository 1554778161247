import React from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { makeStyles, useTheme } from '@mui/styles';
import {
  Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Popper,
  Typography,
  Stack
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import config from 'config';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import getUser from 'utils/auth';

// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: '100%',
    minWidth: '100px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%'
    }
  },
  headerAvatar: {
    cursor: 'pointer',
    ...theme.typography.mediumAvatar,
    margin: '8px 0 8px 8px !important'
  },
  profileChip: {
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: `${theme.palette.primary.main}!important`,
      color: theme.palette.primary.light,
      '& svg': {
        stroke: theme.palette.primary.light
      }
    }
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px'
  },
  listItem: {
    marginTop: '5px'
  },
  cardContent: {
    padding: '16px !important'
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: '16px',
    marginTop: '16px'
  },
  flex: {
    display: 'flex'
  },
  name: {
    marginLeft: '2px',
    fontWeight: 400
  },
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'hidden'
  },
  badgeWarning: {
    backgroundColor: theme.palette.warning.dark,
    color: '#fff'
  }
}));

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const navigate = useNavigate();
  const [user, setUser] = React.useState({});

  const handleLogout = React.useCallback(async () => {
    localStorage.removeItem('user');
    navigate('/auth/login');
  }, [navigate]);

  React.useEffect(() => {
    const user = getUser();
    if (user) {
      setUser(user);
    } else {
      handleLogout();
    }
  }, [handleLogout]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let role = '未知角色';

  switch (user.role) {
    case 9:
      role = '超级管理员';
      break;
    case 8:
      role = '管理员';
      break;
    case 1:
      role = '普通用户';
      break;
    default:
  }

  return (
    <>
      <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        icon={
          <Avatar
            src={User1}
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <ClickAwayListener onClickAway={handleClose}>
              <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                <CardContent className={classes.cardContent}>
                  <Stack flexDirection="row" justifyContent="center">
                    <Typography component="span" variant="h4" className={classes.name}>
                      {user.name}
                    </Typography>
                    <Typography variant="subtitle2">({role})</Typography>
                  </Stack>
                  <List component="nav" className={classes.navContainer}>
                    <ListItemButton className={classes.listItem} sx={{ borderRadius: `${config.borderRadius}px` }} onClick={handleLogout}>
                      <ListItemIcon>
                        <IconLogout stroke={1.5} size="1.3rem" />
                      </ListItemIcon>
                      <ListItemText primary={<Typography variant="body2">退出</Typography>} />
                    </ListItemButton>
                  </List>
                </CardContent>
              </MainCard>
            </ClickAwayListener>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
