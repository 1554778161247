// assets
import { IconReceipt, IconStar } from '@tabler/icons';

// ===========================|| TRADE MENU ITEMS ||=========================== //

const trade = {
  id: 'trade',
  title: '订单',
  type: 'group',
  children: [
    {
      id: 'trade-list',
      title: '订单管理',
      type: 'item',
      url: '/trade/list',
      icon: IconReceipt,
      breadcrumbs: false
    },
    {
      id: 'trade-favorite',
      title: '案例库',
      type: 'item',
      url: '/trade/favorites',
      icon: IconStar,
      breadcrumbs: false
    }
  ]
};

export default trade;
