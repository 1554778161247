import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import axios from 'axios';
import config from 'config';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import 'react-image-lightbox/style.css';

// mock
// import './mock';

import getUser from 'utils/auth';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

// ===========================|| APP ||=========================== //

const App = () => {
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    axios.interceptors.request.use((config) => {
      const user = getUser();
      if (user) {
        config.headers.Authorization = `Bearer ${user.token}`;
      }
      if (process.env.NODE_ENV === 'production') {
        config.baseURL = 'https://kademu.jhmeeting.com';
      }
      return config;
    });
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // Do something with response error
        const { response } = error;

        if (response.status === 401) {
          window.location = '/auth/login';
        } else {
          setAlertMessage(response.data);
        }

        throw new Error(`${response.status}: ${response.data}`);
      }
    );
  }, []);

  const handleAlertClose = () => {
    setAlertMessage('');
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider
        theme={themes({
          isOpen: [], // for active default menu
          fontFamily: config.fontFamily,
          borderRadius: config.borderRadius,
          opened: true
        })}
      >
        <CssBaseline />
        <Snackbar
          open={Boolean(alertMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={1500}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
