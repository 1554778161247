// assets
import { IconBuildingStore, IconAlarm, IconCreditCard, IconReportMoney, IconAffiliate, IconCurrencyYen } from '@tabler/icons';

// ===========================|| MERCHANT MENU ITEMS ||=========================== //

const merchant = {
  id: 'merchant',
  title: '商家',
  type: 'group',
  children: [
    {
      id: 'merchant-verifed',
      title: '已审核店铺',
      type: 'item',
      url: '/merchant/verified',
      icon: IconBuildingStore,
      breadcrumbs: false
    },
    {
      id: 'merchant-pending',
      title: '待审核店铺',
      type: 'item',
      url: '/merchant/pending',
      icon: IconAlarm,
      breadcrumbs: false
    },
    {
      id: 'merchant-withdraw',
      title: '提现申请',
      type: 'item',
      url: '/merchant/withdraw',
      icon: IconCreditCard,
      breadcrumbs: false
    },
    {
      id: 'merchant-charge',
      title: '充值记录',
      type: 'item',
      url: '/merchant/charge',
      icon: IconReportMoney,
      breadcrumbs: false
    },
    {
      id: 'merchant-balance',
      title: '余额增减',
      type: 'item',
      url: '/merchant/balance',
      icon: IconCurrencyYen,
      breadcrumbs: false
    },
    {
      id: 'merchant-owner',
      title: '认领店铺',
      type: 'item',
      url: '/merchant/claim',
      icon: IconAffiliate,
      breadcrumbs: false
    }
  ]
};

export default merchant;
