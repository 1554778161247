import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

// ===========================|| LOGO SVG ||=========================== //

const Logo = ({ sx }) => {
  const theme = useTheme();

  return (
    <MuiTypography variant="h3" sx={{ color: theme.h3, ...sx }}>
      卡德姆后台
    </MuiTypography>
  );
};

Logo.propTypes = {
  sx: PropTypes.object
};

export default Logo;
